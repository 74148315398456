import {Injectable} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {SupportService} from '../services/support.service';
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: 'root'})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private supportService: SupportService, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If no authorization header is added. Add the one from authservice
    if (!request.headers.has("Authorization") && this.authService.clientToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', this.authService.clientToken)
      });
    }

    if (!request.headers.has("Partner-Authorization") && this.authService.partnerToken) {
      request = request.clone({
        headers: request.headers.set('Partner-Authorization', this.authService.partnerToken)
      });
    }

    return next.handle(request).pipe(
      filter((event: any) => event instanceof HttpResponse),
      // Add open replay event logger for replay sessions
      map((response: HttpResponse<any>) => {
        this.supportService.sendEventToReplaySession(`${request.url}`, {request, response});
        return response;
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
