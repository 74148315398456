import {Injectable, isDevMode} from '@angular/core';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import {User} from 'app/shared/models/entity/user';
import {AuthService} from './auth.service';
import {HttpRequest, HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {environment} from 'environments/environment';

type ReqRespType = {
  request: HttpRequest<any>;
  response: HttpResponse<any>;
};

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private tracker: Tracker;
  private static sessionToken;

  constructor(private authService: AuthService) {
  }

  public start(): void {
    this.initTracker();
    this.setSubscriptions();
  }

  private initTracker(): void {
    if (!isDevMode()) {
      this.tracker = new Tracker({
        projectKey: environment.openreplay.key,
        obscureInputDates: false,
        obscureInputEmails: false,
        obscureInputNumbers: false,
        obscureTextEmails: false,
        obscureTextNumbers: false,
        respectDoNotTrack: false,
        defaultInputMode: 0,
        sessionToken: SupportService.sessionToken,
        // __DISABLE_SECURE_MODE: true, // This is only for testing purposes on localhost! Disable for production!

      });
      this.tracker.use(
        trackerAssist({
          onAgentConnect: () => console.log('Live support session started!'),
          onCallStart: () => console.log('Support call started!'),
          onRemoteControlStart: () => console.log('Support remote control started!'),
        })
      );
      this.tracker.start()
        .then((startedSession) => {
          if (startedSession.success) {
            console.log('Openreplay support service started with id: ' + startedSession.sessionID);
            SupportService.sessionToken = startedSession.sessionToken;
          }
        });
      this.setTrackerUser(null);
    }
  }

  private setSubscriptions(): void {
    if (!isDevMode()) {
      this.authService.activeUser.subscribe((user) => this.onUserChange(user));
    }
  }

  private onUserChange(user: User): void {
    if (user) {
      this.setTrackerUser(user);
    }
  }

  private setTrackerUser(user: User): void {
    this.tracker.setUserID(user ? `${user.mail} - ${user.name} - ${user.id}` : 'unknown');
    this.tracker.setMetadata('username', user ? user.name : 'unknown');
    this.tracker.setMetadata('login', user ? user.mail : 'unknown');
    this.tracker.setMetadata('userId', user ? '' + user.id : 'unknown');

    this.tracker.setMetadata('vat', user && user.subscriber ? user.subscriber.vat : 'unknown');
    this.tracker.setMetadata('subscriber', user && user.subscriber ? user.subscriber.name : 'unknown');
    this.tracker.setMetadata('subscriberId', user && user.subscriber ? '' + user.subscriber.id : 'unknown');
  }

  public sendEventToReplaySession(event: string, params: ReqRespType): void {
    const {request, response} = params;
    this.tracker?.event(moment()
      .format('YYYY-MM-DD HH:mm:ss') + ' => ' + event + (request.body?.graphql ? ' - ' + request.body.name : '') + ' [request]', {
      request,
    });
    this.tracker?.event(moment()
      .format('YYYY-MM-DD HH:mm:ss') + ' => ' + event + (request.body?.graphql ? ' - ' + request.body.name : '') + ' [response]', {
      response,
    });
  }
}
