import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    this.reloadOnChuncksFailed(error);
  }

  // This happens when a release happens and the program is live and loaded in cache.
  reloadOnChuncksFailed(error: any) {
    const chunkFailedMessage = /Failed to fetch dynamically imported module/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
